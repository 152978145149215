import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import styled from "styled-components"
const Base = styled.div`
  margin-bottom: 50px;
`

const ImageWrapper = styled.div`
  float: right;
  width: 35%;
  background-color: #d4d3d3;
  margin-top: -120px;
  margin-left: 15px;
  margin-bottom: 15px;
  max-height: 460px;
  overflow: hidden;

  .gatsby-image-wrapper {
    opacity: 0.75;
  }
`
const BaseInner = styled.div`
  position: relative;
  padding-top: 120px;

  &::before {
    content: "";
    height: 5px;
    width: 300px;
    background-color: #1f1f1f;
    position: absolute;
    top: 60px;
  }

  p {
    line-height: 30px;
  }
`

const Images = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-left: -10px;
  margin-right: -10px;
`
const ImagesItem = styled.li`
  background-color: #d4d3d3;
  flex-basis: calc(50% - 20px);
  margin: 10px;

  .gatsby-image-wrapper {
    opacity: 0.75;
  }
`

const BasicLayout = ({
  pageName = "",
  description = "",
  rightImage = "",
  images = [],
}) => {
  const renderImages = () => {
    return images.map((image, index) => {
      return (
        <ImagesItem key={index}>
          <StaticImage
            src="../media/images/basic_layout_placeholder_image_1.png"
            alt="About page side image"
            quality="100"
          />
        </ImagesItem>
      )
    })
  }

  return (
    <Base className={`container ${pageName}-page`}>
      <BaseInner className={`${pageName}`}>
        {rightImage !== "" && (
          <ImageWrapper>
            <StaticImage
              src="../media/images/basic_layout_placeholder_right.png"
              alt="About page side image"
              quality="100"
            />
          </ImageWrapper>
        )}
        {description !== "" && <ReactMarkdown children={description} />}
      </BaseInner>
      {images.length > 0 && <Images>{renderImages()}</Images>}
    </Base>
  )
}

export default BasicLayout
