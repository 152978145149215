import * as React from 'react'
import HomeVid from '../media/videos/group_training.mp4'
import HomeVidPoster from '../media/videos/group_training_poster.jpg'
import { graphql, useStaticQuery } from 'gatsby'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderVideo from '../components/headerVideo'

// import templates
import BasicLayout from '../templates/basic-layout'

const seo_description = `Group Training sessions with Ty Paul offers a range of innovative treatments and techniques to all clients of different ages and fitness levels`
const seo_keywords = `all fitness levels, clients of different ages, Britain's Best Coach, Personal training treatments, Personal training techniques, , personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const GroupTraining = () => {
  const data = useStaticQuery(query)
  const { strapiGroupTraining } = data
  const { title, description, SEO } = strapiGroupTraining

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderVideo src={HomeVid} poster={HomeVidPoster} title={title} />
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
    </Layout>
  )
}

const query = graphql`
  {
    strapiGroupTraining {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default GroupTraining
